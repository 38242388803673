import React from 'react'
import { HeaderAndFooterLayout } from '../layouts/HeaderAndFooter'
import { css } from '../../styled-system/css'
import Pargh from '../components/photographie/Pargh'
import { HeadFC,  navigate, PageProps } from 'gatsby'
import { FeedBack, Styles } from '../types/photo'
import '../styles/phototxt.css'
import ImageDisp from '../components/photographie/ImageDisp'
import { Feedbacks_title } from '../components/photographie/Feedbacks'
import '../styles/photo_landing_cover.css'
import ResponsiveImage from '../components/photographie/ResponsiveImage'
import ButtonOrd from '../components/photographie/ButtonOrd'
import SEO from '../components/SEO'

interface PhotoTemplateProps extends PageProps {
  pageContext: {
    id: string
    category: string
    titre: string
    type: string
    cover: string
    titles: string[]
    HtmlText: string[]
    images: string[]
    feedback: FeedBack[]
    name: string
    styles_name: string[]
    styles: Styles
    imageAlt: string
    SEO: {
      title: string
      description: string
      robotsContent: string
    }
  }
}

const PhotoTemplate: React.FC<PhotoTemplateProps> = ({ pageContext }) => {
  const {
    id,
    category,
    cover,
    type,
    titre,
    titles,
    HtmlText,
    images,
    styles_name,
    styles,
    imageAlt,
  } = pageContext

  const getBackground = (cover: string) => {
    if (styles[styles_name[1]] && styles[styles_name[1]].backgroundColor) {
      return styles[styles_name[1]].backgroundColor
    }
    return 'transparent'
  }

  const handleClick = () => {
    localStorage.setItem('pageId', category)
    navigate('/photographie/galerie')
  }
  return (
    <HeaderAndFooterLayout type={type}>
      <div
        className={css({
          overflow: 'hidden',
          height: 'calc(100vh - 9rem)',
          width: '100%',
          position: 'relative',
          borderBottom: 'solid 8px #F2D98D',
        })}
      >
        <div
          className={css({
            position: 'absolute',
            width: '50%',
            height: '500px',
            textAlign: 'center',
            fontSize: '36px',
            fontWeight: 'bold',
            zIndex: '1',
            top: '20%',
            left: '45%',
            '@media (max-width: 768px)': {
              left: '0%',
              width: '100%',
            },
          })}
        >
          <h1
            className={css({
              width: '100%',
              textAlign: 'center',
              fontSize: '2rem',
              fontWeight: 'bold',
              marginTop: '2.5rem',
              marginBottom: '2.5rem',
              zIndex: '1',
              '@media (max-width: 768px)': {
                fontSize: '30px',
              },
            })}
            style={styles[styles_name[2]]}
          >
            {titre}
          </h1>
          <ButtonOrd
            // left={'50%'}
            // top={(typeof window !== 'undefined' ? (window.innerWidth <= 768) : false) ? '30%' : '50%'}
            pkgId={id}
          />
        </div>

        <div
          className={css({
            display: 'flex',
            flexDir: 'row',
            height: '100%',
          })}
        >
          <div
            style={styles[styles_name[0]]}
            className={css({
              '@media (max-width: 768px)': {
                width: '100% !',
              },
            })}
          >
            <ResponsiveImage
              className={css({
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                zIndex: '-1',
              })}
              imageName={cover}
              background={getBackground(cover)}
              imageAlt={imageAlt ?? ''}
            ></ResponsiveImage>
          </div>
          <div
            style={styles[styles_name[1]]}
            className={css({
              '@media (max-width: 768px)': {
                display: 'none',
              },
            })}
          ></div>
        </div>
      </div>
      {/* ----------------------------------Presentation --------------------------------------*/}
      <div className={css({ width: '100%', margin: '50px auto 0' })}>
        <h2
          className={css({
            fontSize: '30px',
            width: '100%',
            textAlign: 'center',
          })}
        >
          {titles[0]}
        </h2>
        <div
          className={css({
            position: 'relative',
            display: 'flex',
            flexDir: 'row',
            margin: '2rem auto 2rem',
            width: '90%',
            gap: '0',
            alignItems: 'center',
            justifyContent: 'center',
            '@media (max-width: 768px)': {
              flexDirection: 'column',
              gap: '20px',
              margin: '0 auto 2rem',
            },
          })}
        >
          <ResponsiveImage
            className={css({
              width: '60%',
              maxWidth: '600px',
              margin: '0 2rem 0 0',
              objectFit: 'cover',
              borderRadius: '4vh ',
              '@media (max-width: 768px)': {
                width: '100%',
                margin: '0 auto',
              },
            })}
            imageName="inna"
          ></ResponsiveImage>

          <Pargh
            className={css({
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              width: '40%',
              textAlign: 'left',
              fontSize: '20px',
              '@media (max-width: 768px)': {
                width: '90%',
                margin: 'auto',
                textAlign: 'center',
                fontSize: '16px',
              },
            })}
            file={HtmlText[0]}
          ></Pargh>
        </div>
        <div
          className={css({
            display: 'flex',
            flexDir: 'row',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '2rem',
          })}
        >
          <ButtonOrd />
        </div>
      </div>
      {/* ---------------------------------- disp --------------------------------------*/}
      <ImageDisp images={images}></ImageDisp>
      <div
        className={css({
          display: 'flex',
          flexDir: 'row',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '2rem',
          marginTop: '1.5rem',
        })}
      >
        <button
          className={`button3 ${css({
            border: 'black 1px solid',
            borderRadius: '0.5rem',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
          })}`}
          onClick={handleClick}
        >
          Voir plus de photos
        </button>
      </div>

      {/* ---------------------------------- body --------------------------------------*/}
      <h2
        className={css({
          fontSize: '30px',
          width: '100%',
          textAlign: 'center',
          margin: '50px auto 0',
        })}
      >
        {titles[1]}
      </h2>
      <div
        className={css({
          display: 'flex',
          flexDir: 'row',
          width: '100%',
          maxWidth: '1300px',
          justifyContent: 'flex-start',
          alignItems: 'center',
          margin: '50px auto 0',
          gap: '15%',
          '@media (max-width: 768px)': {
            flexDir: 'column',
          },
        })}
      >
        <ResponsiveImage
          className={css({
            width: '40%',
            aspectRatio: '1',
            objectFit: 'cover',
            borderRightRadius: '4vh',
            '@media (max-width: 768px)': {
              width: '100%',
              margin: 'auto',
              borderRadius: '4vh 4vh 0 0',
            },
          })}
          imageName={images[0]}
        ></ResponsiveImage>
        <Pargh
          className={`${css({
            width: '600px',
            fontSize: '10px',
            '@media (max-width: 768px)': {
              width: '90%',
              margin: 'auto',
              textAlign: 'center',
            },
          })} phototext`}
          file={HtmlText[1]}
        ></Pargh>
      </div>
      {/*-------------------------------------*/}
      <div
        className={css({
          display: 'flex',
          flexDir: 'row',
          width: '100%',
          maxWidth: '1300px',
          justifyContent: 'flex-start',
          alignItems: 'center',
          margin: '50px auto 0',
          gap: '15%',
          '@media (max-width: 768px)': {
            flexDir: 'column',
          },
        })}
      >
        <Pargh
          className={`${css({
            width: '40%',
            fontSize: '20px',
            '@media (max-width: 768px)': {
              display: 'none',
            },
          })} phototext`}
          file={HtmlText[2]}
        ></Pargh>
        <ResponsiveImage
          className={css({
            width: '40%',
            objectFit: 'cover',
            borderLeftRadius: '4vh',
            '@media (max-width: 768px)': {
              display: 'none',
            },
          })}
          imageName={images[1]}
        ></ResponsiveImage>
        {/*------------------ Phone Version ------------------*/}
        <ResponsiveImage
          className={css({
            width: '40%',
            objectFit: 'cover',
            borderRightRadius: '4vh',
            display: 'none',
            '@media (max-width: 768px)': {
              display: 'block',
              width: '100%',
              margin: 'auto',
              borderRadius: '4vh 4vh 0 0',
            },
          })}
          imageName={images[1]}
        ></ResponsiveImage>
      </div>
      <Pargh
        className={`${css({
          width: '40%',
          fontSize: '20px',
          display: 'none',
          '@media (max-width: 768px)': {
            display: 'block',
            width: '90%',
            margin: 'auto',
            textAlign: 'center',
          },
        })} phototext`}
        file={HtmlText[2]}
      ></Pargh>
      {/*-------------------------------------*/}
      <div
        className={css({
          display: 'flex',
          flexDir: 'row',
          width: '100%',
          maxWidth: '1300px',
          justifyContent: 'flex-start',
          alignItems: 'center',
          margin: '50px auto 0',
          gap: '15%',
          '@media (max-width: 768px)': {
            flexDir: 'column',
          },
        })}
      >
        <ResponsiveImage
          className={css({
            width: '40%',
            objectFit: 'cover',
            borderRightRadius: '4vh',
            '@media (max-width: 768px)': {
              width: '100%',
              margin: 'auto',
              borderRadius: '4vh 4vh 0 0',
            },
          })}
          imageName={images[2]}
        ></ResponsiveImage>

        <Pargh
          className={`${css({
            width: '40%',
            fontSize: '20px',
            '@media (max-width: 768px)': {
              width: '90%',
              margin: 'auto',
              textAlign: 'center',
            },
          })} phototext`}
          file={HtmlText[3]}
        ></Pargh>
      </div>
      <div
        className={css({
          margin: '100px auto 0',
          width: '50%',
          borderBottom: 'solid 2px #594A2E',
        })}
      ></div>
      {/*-------------------------feedBacks -----------------------------*/}
      <div className={css({ width: '100%', margin: '100px auto 0' })}>
        <Feedbacks_title id={id} title={titles[2]} />
      </div>
    </HeaderAndFooterLayout>
  )
}

export default PhotoTemplate

export const Head: HeadFC = ({ pageContext }: PhotoTemplateProps) => {
  return (
    <>
      <SEO
        title={pageContext.SEO.title}
        description={pageContext.SEO.description}
        robotsContent={pageContext.SEO.robotsContent}
      />
      <link
        rel="canonical"
        href={`https://www.digituniverse.fr/photographie/photos/${pageContext.id}`}
      />
    </>
  )
}
