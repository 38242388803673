// ImageDisp.tsx
import React, { useState } from 'react';
import { css } from '../../../styled-system/css';
import ResponsiveImage from './ResponsiveImage';

interface ImageDispProps {
  images: string[];
}

const ImageDisp: React.FC<ImageDispProps> = ({ images }) => {
  if (!images || images.length === 0) {
    return null;
  }

  const [current, setCurrent] = useState(0);

  function goNext() {
    setCurrent((prevCurrent) => (prevCurrent + 1) % images.length);
  }

  function goPrev() {
    setCurrent((prevCurrent) => (prevCurrent - 1 + images.length) % images.length);
  }

  return (
    <>
      <div
        className={css({
          display: 'flex',
          margin: '100px auto 0',
          width: '100%',
          aspectRatio: '3',
          borderBottom: 'solid 6px #F2D98D',
          borderTop: 'solid 6px #F2D98D',
          '@media (max-width: 768px)': {
            display: 'none',
          },
        })}
      >
        {images.map((image, index) => (
          <ResponsiveImage
            key={index}
            className={css({ width: '25%', height: '100%', objectFit: 'cover' })}
            imageName={image}
          />
        ))}
      </div>

      <div
        className={css({
          width: '80%',
          height: '600px',
          margin: 'auto',
          position: 'relative',
          overflow: 'hidden',
          display: 'none',
          '@media (max-width: 768px)': {
            display: 'block',
            width: '100%',
          },
        })}
      >
        <ResponsiveImage
          className={css({
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          })}
          imageName={images[current]}
        />
        <button
          className={css({
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            border: 'none',
            padding: '10px',
            cursor: 'pointer',
            zIndex: '10',
            fontSize: '30px',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
          })}
          onClick={goPrev}
        >
          &#10094;
        </button>
        <button
          className={css({
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            border: 'none',
            padding: '10px',
            cursor: 'pointer',
            fontSize: '30px',
            zIndex: '10',
            right: '0',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
          })}
          onClick={goNext}
        >
          &#10095;
        </button>
      </div>
    </>
  );
};

export default ImageDisp;
